import {
  Box,
  HStack,
  VStack,
  Tabs,
  TabList,
  Tab,
  TabIndicator,
  TabPanels,
  TabPanel,
  Text,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { styles } from "./style.module";
import { analyticsDashboardSaga } from "./saga";
import { useDispatch, useSelector } from "react-redux";
import * as selectors from "./selectors";
import {  masterConfig, formatConfig } from "../../../masterConfig";
import _ from "lodash";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { actions, reducer, sliceKey } from "./slice";
import { format } from "date-fns";
import { getDateDifferenceInfo, getFromDate } from "utils/commonFunctions";
import { v4 as uuidv4 } from "uuid";
import Filters from "./components/Filters";
import MeasuredCommodities from "./components/MeasuredCommodities";
import CommodityHealth from "./components/CommodityHealth/CommodityHealth";
import WarehouseScoreComp from "./components/WarehouseScoreComp/WarehouseScoreComp";
import InspectionCount from "./components/InspectionCount";
import GeographicalReach from "./components/GeographicalReach";
import TotalInspections from "./components/TotalInspections/TotalInspections";
import QuantityInspected from "./components/QuantityInspected/QuantityInspected";
import NoOfBags from "./components/NoOfBags/NoOfBags";
import Commodities from "./components/Commodities/Commodities";
import DataWidget from "./components/DataWidget";
import SitesInspected from "./components/SitesInspected/SitesInspected";
import SectionHeader from "./components/SectionHeader";
import dataAdapter from "./dataAdaptor";
import TableSimple from "./components/TableSimple";
import BarChart from "./components/BarChart";
import { ErrorBoundary } from "react-error-boundary";
import { useUserContext } from "../../../context/UserContext";
import { getCurrentProfile } from "utils/commonFunctions";
import ErrorFallback from "errorsFallback/ErrorFallback";
function AnalyticsDashboard() {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: analyticsDashboardSaga });

  //useStates

  const [token, setToken] = useState(null);
  //Range Picker
  const [filters, setFilters] = useState({
    fromDate: getFromDate(),
    toDate: new Date(),
  });
  const { fromDate, toDate } = filters;
  const [filterQuery, setFilterQuery] = useState({
    location: "",
    clientIds: {
      id: null,
      value: null,
    },
    commodities: "",
  });
  const { clientIds, commodities, location} = filterQuery;
  const [selectedDataType, setSelectedDataType] = useState("inspectionsCount");
  const [selectedDateType, setSelectedDateType] = useState("week");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedWarehouse, setSelectedWarehouse] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [inputValue, setInputValue] = useState(selectedWarehouse || "");
  const [disabledDateTypes, setDisabledDateTypes] = useState([]);
  const [errorToast, setErrorToast] = useState(true);

  const [selectedOption, setSelectedOption] = useState("Inspection Count");

  const [tab, setTab] = useState(null);

  const { userProfileData } = useUserContext();
  const entity = getCurrentProfile(userProfileData?.profiles);
  const entityId = entity?.ownerEntityId;
  const entityType = entity?.ownerEntityType;

  const handleTabChange = (val) => {
    setTab(val);
  };

  const handleFilterQuery = (option, property) => {
    setFilterQuery((prevFilters) => {
      switch (property) {
        case "clientIds":
          const client = dataAdapter(state, "clientName")?.find(
            (client) => client.id === option
          );
          return {
            ...prevFilters,
            [property]: { id: option, value: client ? client.value : "" },
          };
        case "location":
          return { ...prevFilters, [property]: option };
        case "commodities":
          return { ...prevFilters, [property]: option };
        default:
          return prevFilters;
      }
    });
  };

  const handleFromDateChange = (date, key) => {
    setFilters((prevState) => ({
      ...prevState,
      fromDate: date,
    }));
  };
  const handleToDateChange = (date, key) => {
    setFilters((prevState) => ({
      ...prevState,
      toDate: date,
    }));
  };
  const handleDataTypeChange = (event) => {
    const selectedIndex = event.target.selectedIndex;
    const selectedOption = event.target.options[selectedIndex].label;
    setSelectedOption(selectedOption);
    const newValue = event.target.value;
    setSelectedDataType(newValue);
  };
  const handleDateType = (type) => {
    setSelectedDateType(type);
  };
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleQuery = (val) => {
    setSearchQuery(val);
  };

  const handleSelect = (value) => {
    setInputValue(value);
    handleQuery(value);
  };

  const handleWareHouseChange = (warehouseID) => {
    setSelectedWarehouse(warehouseID);
  };

  const handleInputVal = (value) => {
    setInputValue(value);
  };
  const dispatch = useDispatch();

  //useSelectors
  const state = useSelector(selectors.state);
  // console.log("state", state);
  // let warehouseInsightsData =
  //   useSelector(selectors.selectWarehouseInsightsData) || [];
  let warehouses = useSelector(selectors.selectWarehouseData) || [];

  useEffect(() => {
    const dateUnit = getDateDifferenceInfo(filters?.fromDate, filters?.toDate);
    setSelectedDateType(dateUnit?.dateType);
    setDisabledDateTypes(dateUnit?.disabled);
  }, [fromDate, toDate]);

  useEffect(() => {
    if (entityId && entityType === "customer") {
      dispatch(
        actions.fetchClientsOfCustomer({
          customerId: entityId,
        })
      );
    }
  }, [entityId]);

  useEffect(() => {
    // Prepare query paramenters to get filtered data from API
    const forQueryParams = {
      warehouseId: selectedWarehouse,
      ...(fromDate &&
        toDate && {
          start_dt: format(fromDate, "yyyy-MM-dd"),
          end_dt: format(toDate, "yyyy-MM-dd"),
        }),
      location: location,
      commodities: commodities,
      clientIds: clientIds.id,
    };
    dispatch(actions.fetchUserContext("web"));
    dispatch(actions.fetchAnalyticsData({ payload: forQueryParams }));
    dispatch(
      actions.fetchBarChartData({
        payload: {
          ...forQueryParams,
          unit: selectedDateType,
        },
      })
    );
    dispatch(
      actions.fetchDoughnutChartData({
        payload: forQueryParams,
      })
    );
    dispatch(
      actions.fetchQuantityInsightsData({
        payload: forQueryParams,
      })
    );
    dispatch(
      actions.fetchWarehouseInsightsData({
        payload: forQueryParams,
      })
    );
    dispatch(
      actions.fetchPortfolioLineChartData({
        payload: forQueryParams,
      })
    );
    dispatch(
      actions.fetchInspectionsLineChartData({
        payload: forQueryParams,
      })
    );
    dispatch(
      actions.fetchQuantityLineChartData({
        payload: forQueryParams,
      })
    );
    dispatch(
      actions.fetchCommoditiesData({
        payload: forQueryParams,
      })
    );
    dispatch(
      actions.fetchMapChartData({
        payload: forQueryParams,
      })
    );
    dispatch(
      actions.fetchWarehouses({
        payload: forQueryParams,
      })
    );
    dispatch(
      actions.fetchWarehouseScore({
        payload: forQueryParams,
      })
    );
  }, [
    fromDate,
    toDate,
    clientIds.id,
    commodities,
    location,
    selectedWarehouse,
    selectedDateType,
  ]);

  const getWidget = useCallback((widgetId, dataSource) => {
    const widgetType = widgetId.split("_")[0];
    const widgetMap = {
      filters: (
        <Filters
          toggleDrawer={toggleDrawer}
          filterQuery={filterQuery}
          commodityNames={dataAdapter(state, "commodity")}
          uniqueObjects={dataAdapter(state, "location")}
          handleFilterQuery={handleFilterQuery}
          isDrawerOpen={isDrawerOpen}
          warehouses={warehouses}
          inputValue={inputValue}
          handleQuery={handleQuery}
          handleSelect={handleSelect}
          handleWareHouseChange={handleWareHouseChange}
          handleInputVal={handleInputVal}
          searchQuery={searchQuery}
          filters={filters}
          handleFromDateChange={handleFromDateChange}
          handleToDateChange={handleToDateChange}
          key={widgetId}
          entityType={entityType}
          clientNames={dataAdapter(state, "clientName")}
        />
      ),
      barChart: (
        <BarChart data={dataAdapter(state, widgetType, _, _, dataSource)} />
      ),
      tableSimple: (
        <TableSimple data={dataAdapter(state, widgetType, _, _, dataSource)} />
      ),
      numberOfInspectionsNCCF1: (
        <DataWidget
          title="Number Of Inspections"
          data={dataAdapter(state, "numberOfInspections1")}
          key={widgetId}
        />
      ),
      quantityInspectedNCCF1: (
        <DataWidget
          title="Quantity Inspected (MT)"
          data={dataAdapter(state, "quantityInspected1")}
          key={widgetId}
        />
      ),
      quantityAcceptedNCCF1: (
        <DataWidget
          title="Quantity Accepted (MT)"
          data={dataAdapter(state, "quantityAccepted1")}
          key={widgetId}
        />
      ),
      quantityUnacceptedNCCF1: (
        <DataWidget
          title="Quantity Unaccepted (MT)"
          data={dataAdapter(state, "quantityUnaccepted1")}
          key={widgetId}
        />
      ),
      trucksInspectedNCCF1: (
        <DataWidget
          title="Trucks Inspected"
          data={dataAdapter(state, "trucksInspected1")}
          key={widgetId}
        />
      ),
      trucksAcceptedNCCF1: (
        <DataWidget
          title="Trucks Accepted"
          data={dataAdapter(state, "trucksAccepted1")}
          key={widgetId}
        />
      ),
      trucksUnacceptedNCCF1: (
        <DataWidget
          title="Trucks Unaccepted"
          data={dataAdapter(state, "trucksUnaccepted1")}
          key={widgetId}
        />
      ),
      quantityInspectedNCCF2: (
        <DataWidget
          title="Quantity Inspected (MT)"
          data={dataAdapter(state, "quantityInspected2")}
          key={widgetId}
        />
      ),
      quantityAcceptedNCCF2: (
        <DataWidget
          title="Quantity Accepted (MT)"
          data={dataAdapter(state, "quantityAccepted2")}
          key={widgetId}
        />
      ),
      quantityUnacceptedNCCF2: (
        <DataWidget
          title="Quantity Unaccepted (MT)"
          data={dataAdapter(state, "quantityUnaccepted2")}
          key={widgetId}
        />
      ),
      samplingNotDoneNCCF1: (
        <DataWidget
          title="Sampling Not Done"
          data={dataAdapter(state, "samplingNotDone1")}
          key={widgetId}
        />
      ),
      bagsInspectedNCCF1: (
        <DataWidget
          title="Bags Inspected"
          data={dataAdapter(state, "bagsInspected1")}
          key={widgetId}
        />
      ),
      trucksInspectedNCCF2: (
        <DataWidget
          title="Trucks Inspected"
          data={dataAdapter(state, "trucksInspected2")}
          key={widgetId}
        />
      ),
      trucksAcceptedNCCF2: (
        <DataWidget
          title="Trucks Accepted"
          data={dataAdapter(state, "trucksAccepted2")}
          key={widgetId}
        />
      ),
      trucksUnacceptedNCCF2: (
        <DataWidget
          title="Trucks Unaccepted"
          data={dataAdapter(state, "trucksUnaccepted2")}
          key={widgetId}
        />
      ),
      quantityInspectedNCCF3: (
        <DataWidget
          title="Quantity Inspected (MT)"
          data={dataAdapter(state, "quantityInspected3")}
          key={widgetId}
        />
      ),
      quantityAcceptedNCCF3: (
        <DataWidget
          title="Quantity Accepted (MT)"
          data={dataAdapter(state, "quantityAccepted3")}
          key={widgetId}
        />
      ),
      quantityUnacceptedNCCF3: (
        <DataWidget
          title="Quantity Unaccepted (MT)"
          data={dataAdapter(state, "quantityUnaccepted3")}
          key={widgetId}
        />
      ),
      samplingNotDoneNCCF2: (
        <DataWidget
          title="Sampling Not Done"
          data={dataAdapter(state, "samplingNotDone2")}
          key={widgetId}
        />
      ),
      bagsInspectedNCCF2: (
        <DataWidget
          title="Bags Inspected"
          data={dataAdapter(state, "bagsInspected2")}
          key={widgetId}
        />
      ),
      header: <SectionHeader data={dataSource} />,
      totalInspections: (
        <TotalInspections
          data={dataAdapter(state, "totalInspections", _, selectedDateType)}
          key={widgetId}
        />
      ),
      quantityInspected: (
        <QuantityInspected
          data={dataAdapter(state, "quantityInspected", _, selectedDateType)}
          key={widgetId}
        />
      ),
      numberOfBags: (
        <NoOfBags
          data={dataAdapter(state, "numberOfBags", _, selectedDateType)}
          key={widgetId}
        />
      ),
      commodities: (
        <Commodities
          data={dataAdapter(state, "commodities", _, selectedDateType)}
          key={widgetId}
        />
      ),
      sitesInspected: (
        <SitesInspected
          data={dataAdapter(state, "sitesInspected", _, selectedDateType)}
          key={widgetId}
        />
      ),
      inspectionCount: (
        <InspectionCount
          selectedOption={selectedOption}
          selectedDateType={selectedDateType}
          handleDateType={handleDateType}
          disabledDateTypes={disabledDateTypes}
          handleDataTypeChange={handleDataTypeChange}
          inspectionCountData={dataAdapter(
            state,
            "inspectionCount",
            selectedDataType
          )}
          key={widgetId}
          selectedDataType={selectedDataType}
        />
      ),
      commodityHealth: (
        <CommodityHealth
          data={dataAdapter(state, "commodityHealth")}
          key={widgetId}
        />
      ),
      measuredCommodities: (
        <MeasuredCommodities
          data={dataAdapter(state, "measuredCommodities")}
          key={widgetId}
        />
      ),
      warehouseScore: (
        <WarehouseScoreComp
          data={dataAdapter(state, "warehouseScore")}
          key={widgetId}
        />
      ),
      geographicalReach: (
        <GeographicalReach
          data={dataAdapter(state, "geographicalReach")}
          key={widgetId}
        />
      ),
    };
    return (
      <ErrorBoundary
        fallback={
          <ErrorFallback
            errorToast={errorToast}
            setErrorToast={setErrorToast}
            message={`Failed to Load ${widgetType}`}
          />
        }
      >
        {" "}
        {widgetMap[widgetType]}
      </ErrorBoundary>
    );
  });

  const { config, tabs } = formatConfig(masterConfig, "webLayout");

  useEffect(() => {
    if (tabs && tabs.length && !tab) {
      setTab(tabs[0].value);
    }
  }, [tabs]);

  // console.log({ tabs });

  return (
    <Box sx={{marginTop :"75px"}}>
      <Tabs w="100%" position="relative" colorScheme="teal">
        <HStack
          w="100%"
          justify="space-between"
          align="flex-start"
        >
          <Text
            sx={{
              color: "#4A5568",
              fontFamily: "Inter",
              fontSize: "26px",
              fontStyle: "normal",
              fontWeight: "600",
              lineHeight: "140%",
            }}
          >
            {tab}
          </Text>
          <TabList>
            <HStack>
              {tabs.map((tab) => (
                <Tab onClick={() => handleTabChange(tab.value)} key={tab.id}>
                  {tab.value}
                </Tab>
              ))}
            </HStack>
          </TabList>
        </HStack>
        <TabPanels>
          {config.centralArea.dashboard.map((tab) => {
            return (
              <TabPanel key={uuidv4()}>
                <VStack sx={styles.tabContainer}>
                  {tab.rows.map((row) => {
                    if (row.rowOrder === 1) {
                      return getWidget("filters");
                    }
                    const widgets = row.widgets;
                    return (
                      <HStack
                        justify="space-between"
                        w="100%"
                        gap={{ base: "16px", md: "24px" }}
                        key={row.rowOrder}
                      >
                        {widgets.map((widget) =>
                          getWidget(widget.id.split("_")[0], widget.dataSource)
                        )}
                      </HStack>
                    );
                  })}
                </VStack>
              </TabPanel>
            );
          })}
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default React.memo(AnalyticsDashboard);