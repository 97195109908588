import React, { useContext, useEffect, useState } from "react";
import { Box, Stack } from "@chakra-ui/react";
import UserTop from "../components/allUsers/UserTop";
import UserFilters from "../components/UserFilters";
import UserTable from "../components/allUsers/UserTable";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { actions, reducer, sliceKey } from "../slice";
import { UserSaga } from "../saga";
import * as selectors from "../selectors";
import { useAddFilter } from "views/Dashboard/context/AddFilterContext";
import { AddFilterContext } from "views/Dashboard/context/AddFilterContext";
import { useUserContext } from "context/UserContext";
import { getCurrentProfile } from "utils/commonFunctions";
import { ErrorBoundary } from "react-error-boundary";
import FallbackUI from "errorsFallback/FallbackUI";
import FallbackToast from "errorsFallback/FallbackToast";

const User = () => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: UserSaga });
  const { userProfileData } = useUserContext();

  const profiles = userProfileData?.profiles;
  const entity = getCurrentProfile(profiles);
  const ownerEntityId = entity?.ownerEntityId;
  const userRole = entity?.role?.name;

  const allUsersForCustomer = useSelector(selectors.selectAllUsersForCustomer);
  const usersProfileImgUrls = useSelector(selectors.selectProfileImgUrlsArr);
  const clientProfileImgUrls = useSelector(
    selectors.selectProfileImgUrlsArrOfClient
  );
  const clientsUsersProfileImgUrls = useSelector(
    selectors.selectProfileImgUrlsArrForClientUser
  );

  const departmentDropDownForCustomer = useSelector(
    selectors.selectDepartmentDropDownForCustomer
  );
  const designationDropDownForCustomer = useSelector(
    selectors.selectDesignationDropDownForCustomer
  );
  const roleDropDownForCustomer = useSelector(
    selectors.selectRoleDropDownForCustomer
  );
  const stateDropDownForCustomer = useSelector(
    selectors.selectStateDropDownForCustomer
  );
  const cityDropDownForCustomer = useSelector(
    selectors.selectCityDropDownForCustomer
  );

  const [isClientUser, setIsClientUser] = useState(false);
  const allUsersForClient = useSelector(selectors.selectAllUsersForClient);

  const [filters, setFilters] = useState({
    fromDate: null,
    toDate: null,
    department: [],
    designation: [],
    role: [],
  });

  const handleSelection = (prevSelectedItems, itemId) => {
    if (prevSelectedItems.includes(itemId)) {
      return prevSelectedItems.filter((id) => id !== itemId);
    } else {
      return [...prevSelectedItems, itemId];
    }
  };

  const handleUserListFilters = (id, key) => {
    setFilters((prevFilters) => {
      switch (key) {
        case "department":
          return {
            ...prevFilters,
            department: handleSelection(prevFilters.department, id, key),
          };
        case "designation":
          return {
            ...prevFilters,
            designation: handleSelection(prevFilters.designation, id, key),
          };
        case "role":
          return {
            ...prevFilters,
            role: handleSelection(prevFilters.role, id, key),
          };

        default:
          return prevFilters;
      }
    });
  };

  const handleFromDateChange = (date) => {
    setFilters((prevState) => ({
      ...prevState,
      fromDate: date,
    }));
  };
  const handleToDateChange = (date) => {
    setFilters((prevState) => ({
      ...prevState,
      toDate: date,
    }));
  };

  const dispatch = useDispatch();
  useEffect(() => {
    if (ownerEntityId) {
      dispatch(actions.fetchAllUsersforCustomer({ ownerEntityId }));
      // client
      dispatch(actions.fetchAllUsersforClient({ ownerEntityId }));
    }
  }, [ownerEntityId]);

  useEffect(() => {
    if (allUsersForCustomer) {
      const profileImgNameArr = allUsersForCustomer?.map((user) => {
        return user.avatar;
      });
      dispatch(actions.fetchProfileImgURLArr(profileImgNameArr));
    }
    // for client
    if (allUsersForClient) {
      const profileImgNameArrForClientUser = allUsersForClient?.map(
        (user) => user.avatar
      );
      const profileImgNameArrOfClient = allUsersForClient?.map(
        (user) => user.clientAvatar
      );

      dispatch(
        actions.fetchProfileImgURLArrForClientUser(
          profileImgNameArrForClientUser
        )
      );
      dispatch(
        actions.fetchProfileImgURLArrOfClient(profileImgNameArrOfClient)
      );
    }
  }, [allUsersForClient, allUsersForCustomer]);

  return (
    <ErrorBoundary fallback={<FallbackUI mtop="80px" minH="80vh" />}>
      <Box mt={"75px"}>
        <Stack spacing={"36px"} direction="column">
          <UserTop
            totalUsers={
              isClientUser
                ? allUsersForClient.length
                : allUsersForCustomer.length
            }
            isClientUser={isClientUser}
            setIsClientUser={setIsClientUser}
            ownerEntityId={ownerEntityId}
            userRole={userRole}
          />
          {/* Users filters is hide now because its integration is not done */}

          {/* <ErrorBoundary
            fallback={<FallbackToast message={`Failed to Load filters`} />}
          >
            <UserFilters
              departmentDropDownForCustomer={departmentDropDownForCustomer}
              designationDropDownForCustomer={designationDropDownForCustomer}
              roleDropDownForCustomer={roleDropDownForCustomer}
              stateDropDownForCustomer={stateDropDownForCustomer}
              cityDropDownForCustomer={cityDropDownForCustomer}
              handleUserListFilters={handleUserListFilters}
              handleFromDateChange={handleFromDateChange}
              handleToDateChange={handleToDateChange}
              filters={filters}
              isClientUser={isClientUser}
            />
          </ErrorBoundary> */}

          {/* <ErrorBoundary fallback={<FallbackUI minH="70vh" />}> */}
            <UserTable
              allUsers={isClientUser ? allUsersForClient : allUsersForCustomer}
              usersProfileImgUrls={
                isClientUser ? clientsUsersProfileImgUrls : usersProfileImgUrls
              }
              clientProfileImgUrls={clientProfileImgUrls}
              ownerEntityId={ownerEntityId}
              userRole={userRole}
              isClientUser={isClientUser}
            />
          {/* </ErrorBoundary> */}
        </Stack>
      </Box>
    </ErrorBoundary>
  );
};

export default User;
