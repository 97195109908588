import React from "react";
import { HStack, VStack, Text, Box, useMediaQuery } from "@chakra-ui/react";
import {
  ChevronDownIcon as CollapseIcon,
  ChevronRightIcon as ExpandIcon,
} from "@chakra-ui/icons";
import Element from "../element";

export default function Section({
  section,
  data,
  formData,
  handleFormDataChange,
  displayData,
  setPage,
  navIndex,
  setNavIndex,
  formErrorData,
  tempError,
  prevPages,
  setSkippedPages,
  page,
}) {
  const [isMobileScreen] = useMediaQuery("(max-width: 872px)");
  return (
    <VStack w="100%" aria-label="section" gap="24px">
      <HStack
        style={{
          background: "#EDF2F7",
          borderRadius: "8px",
          padding: "8px 16px 8px 8px",
          width: "100%",
        }}
        justify="space-between"
        align="flex-start"
      >
        <HStack justify="flex-start" align="flex-start">
          <CollapseIcon w="26px" h="26px" color="#718096" />
          <VStack align="flex-start">
            <Text
              color="#4A5568"
              fontSize={isMobileScreen ? "16px" : "22px"}
              fontWeight="600"
              lineHeight="1.4"
            >
              {section.title}
            </Text>
            <Text
              color="#718096"
              fontSize={isMobileScreen ? "10px" : "12px"}
              fontWeight="400"
              lineHeight="1.4"
            >
              {section.description}
            </Text>
          </VStack>
        </HStack>
        {/* <Text color="#4A5568" fontSize="16px" fontWeight="400" lineHeight="1.4">
          0 / 1 (0%)
        </Text> */}
      </HStack>
      {data.map((el, index) => {
        const elements = el.component.structure?.elements;
        return (
          <>
            <VStack
              w="100%"
              gap="16px"
              key={index}
              sx={{
                padding: "20px",
                width: "100%",
                border: "1.5px solid #CBD5E0",
                borderRadius: "15px",
                background: "#FFF",
                boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
              }}
              aria-label="component"
            >
              {elements.map((element) => {
                return (
                  <Box
                    aria-label="element"
                    key={element.id}
                    style={{
                      padding: "20px",
                      width: "100%",
                      border: "1.5px solid #CBD5E0",
                      borderRadius: "15px",
                      background: "#FFF",
                      boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
                    }}
                  >
                    <Text
                      aria-label="element label"
                      color="#2D3748"
                      fontSize="16px"
                      fontWeight="500"
                      lineHeight="1.4"
                    >
                      {element.label}
                      {element.mandatory ? (
                        <span
                          style={{
                            color: "#D93025",
                            paddingLeft: "6px",
                            fontSize: "20px",
                          }}
                        >
                          *
                        </span>
                      ) : (
                        <></>
                      )}
                    </Text>
                    <Element
                      elementType={element.htmlType}
                      formData={formData}
                      handleFormDataChange={handleFormDataChange}
                      data={{ element }}
                      setPage={setPage}
                      displayData={displayData}
                      navIndex={navIndex}
                      setNavIndex={setNavIndex}
                      formErrorData={formErrorData}
                      setSkippedPages={setSkippedPages}
                      prevPages={prevPages}
                      page={page}
                    />
                    <Text
                      aria-label="element label"
                      color="#718096"
                      fontSize="12px"
                      fontWeight="400"
                      lineHeight="1.4"
                    >
                      {element.supportingText}
                    </Text>
                  </Box>
                );
              })}
            </VStack>
          </>
        );
      })}
    </VStack>
  );
}
