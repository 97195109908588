import { useState } from "react";
import "./index.css";

import { Image } from "@chakra-ui/react";
import Banner from "components/Layout/auth/Banner/Banner";
import { LoginForm } from "@agnext/reactlib";
import { useHistory, useLocation } from "react-router-dom";

import inspeqtLogo from "../../../assets/inspeqtLogo.svg";

import { validateOTP } from "services/apis";
import { resendFPOTP } from "services/apis";

const SendOTP = () => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const oid = searchParams.get("oid");
  const email = searchParams.get("email");
  const [otp, setOtp] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);

  const otpInputHandler = (val) => {
    setOtp(val);
    setError("");
  };

  const resendCodeHandler = async () => {
    setError("");
    setResendLoading(true);
    try {
      const fpResponse = await resendFPOTP(oid, "forgot_password");
      const { otpId, email } = fpResponse.data.payload;
      history.push(`/auth/sendOtp?oid=${otpId}&email=${email}`);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.reason) {
        setError(error.response.data.reason);
        if (error.response.data.reason === "Email send failed") {
        }
      } else {
        setError("Internal server error");
        setSec(0);
      }
    }
    setResendLoading(false);
  };

  const nextBtnHandler = async () => {
    setLoading(true);
    try {
      const otpVerifyRes = await validateOTP(otp, oid);
      history.push(`/auth/newPassword?oid=${oid}`);
    } catch (error) {
      try {
        const otpVerifyRes = await validateOTP(otp, oid);
        history.push(`/auth/newPassword?oid=${oid}`);
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.reason
        ) {
          setError(error.response.data.reason);
        } else {
          setError("Internal server error");
        }
        setLoading(false);
      }
    }
  };

  return (
    <LoginForm
      type="otp"
      logo={<Image w="100%" h="130%" mt="20px" src={process.env.REACT_APP_LOGO_URL} alt="inspeqt-logo" />}
      leftComponent={<Banner />}
      otp={otp}
      onChangeOtp={otpInputHandler}
      handleSubmit={nextBtnHandler}
      handleResendOTP={resendCodeHandler}
      error={error}
      email={email}
      resendTime={{ minutes: 0, seconds: 59 }}
    />
  );
};

export default SendOTP;