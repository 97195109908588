import React from "react";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import Eye from "assets/svg/eye-icon.svg";
import Edit from "assets/svg/edit-icon.svg";
import { Table } from "@agnext/reactlib";
import { extractDayMonthYearFromTimestamp } from "utils/commonFunctions";
import AvatarWithName from "views/jobs/components/viewJob/Table Components/AvatarWithName";
import { capitalizeStringUpdated } from "utils/commonFunctions";

const Icons = ({ userId, customerId, userRole, clientId = "" }) => {
  // console.log(clientId);
  return (
    <Flex gap={2}>
      <Link
        to={
          clientId
            ? `/admin/users/viewUser?userId=${userId}&customerId=${customerId}&clientId=${clientId}`
            : `/admin/users/viewUser?userId=${userId}&customerId=${customerId}`
        }
      >
        <Box
          background={"#EDF2F7"}
          height={"40px"}
          width={"48px"}
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRadius={"12px"}
          style={{ cursor: "pointer" }}
        >
          <Image src={Eye} alt="" />
        </Box>
      </Link>
      {userRole !== "manager" ? (
        <Link
          to={
            clientId
              ? `/admin/users/updateUser?userId=${userId}&customerId=${customerId}&clientId=${clientId}`
              : `/admin/users/updateUser?userId=${userId}&customerId=${customerId}`
          }
        >
          <Box
            background={"#EDF2F7"}
            height={"40px"}
            width={"48px"}
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderRadius={"12px"}
            style={{ cursor: "pointer" }}
          >
            <Image src={Edit} alt="" />
          </Box>
        </Link>
      ) : (
        <></>
      )}
    </Flex>
  );
};

const UserTable = ({
  ownerEntityId,
  allUsers,
  usersProfileImgUrls,
  clientProfileImgUrls,
  userRole,
  isClientUser,
}) => {
  // UserTable
  // for my users
  const HeaderForMyUsers = [
    {
      displayText: "Full Name",
      keyText: "fullName",
      hasSort: false,
    },
    {
      displayText: "Department",
      keyText: "department",
      hasSort: true,
    },
    {
      displayText: "Designation",
      keyText: "designation",
      hasSort: true,
    },
    {
      displayText: "Role",
      keyText: "role",
      hasSort: true,
    },
    {
      displayText: "Location",
      keyText: "location",
      hasSort: true,
    },
    {
      displayText: "Email",
      keyText: "email",
      hasSort: true,
    },
    {
      displayText: "Created Date",
      keyText: "createdDate",
      hasSort: true,
    },
    {
      displayText: "Actions",
      keyText: "actions",
    },
  ];
  // console.log(allUsers);
  const tableListForMyUsers = allUsers?.map((user, index) => ({
    fullName: (
      <AvatarWithName
        avatarUrl={usersProfileImgUrls ? usersProfileImgUrls[index]?.url : ""}
        name={
          capitalizeStringUpdated(user.firstName + " " + user.lastName) || "-"
        }
      />
    ),
    department: capitalizeStringUpdated(user.department) || "-",
    designation: capitalizeStringUpdated(user.designation) || "-",
    actions: (
      <Icons
        userId={user.userId}
        customerId={ownerEntityId}
        userRole={userRole}
      />
    ),
    role: user.roles?.length
      ? capitalizeStringUpdated(
          user?.roles
            ?.map((role) => role?.roleName)
            .join(", ")
            .split("-")
            .join(" ")
        )
      : // capitalizeStringUpdated(user.roles[0]?.roleName)
        "-",
    location:
      user.addresses && user.addresses.length > 0
        ? user.addresses[0]?.city || user.addresses[0]?.state
          ? capitalizeStringUpdated(
              `${user.addresses[0]?.city ?? ""}${
                user.addresses[0]?.city && user.addresses[0]?.state ? ", " : ""
              }${user.addresses[0]?.state ?? ""}`
            )
          : "-"
        : "-",
    email: user.email,
    createdDate: extractDayMonthYearFromTimestamp(user.creationDt) || "-",
  }));

  // for Client Users
  const HeaderForClientUsers = [
    {
      displayText: "Full Name",
      keyText: "fullName",
      hasSort: false,
    },
    {
      displayText: "Client Name",
      keyText: "clientName",
      hasSort: false,
    },
    {
      displayText: "Role",
      keyText: "role",
      hasSort: true,
    },
    {
      displayText: "Location",
      keyText: "location",
      hasSort: true,
    },
    {
      displayText: "Email",
      keyText: "email",
      hasSort: true,
    },
    {
      displayText: "Created Date",
      keyText: "createdDate",
      hasSort: true,
    },
    {
      displayText: "Actions",
      keyText: "actions",
    },
  ];
  // console.log(allUsers);
  const tableListForClientUser = allUsers?.map((user, index) => ({
    fullName: (
      <AvatarWithName
        bg="red"
        avatarUrl={usersProfileImgUrls ? usersProfileImgUrls[index]?.url : ""}
        name={capitalizeStringUpdated(user.firstName + " " + user.lastName)}
      />
    ),
    clientName: (
      <AvatarWithName
        avatarUrl={clientProfileImgUrls ? clientProfileImgUrls[index]?.url : ""}
        name={capitalizeStringUpdated(user.clientName)}
      />
    ),
    actions: (
      <Icons
        userId={user.userId}
        customerId={ownerEntityId}
        userRole={userRole}
        clientId={user.clientId}
      />
    ),
    role: user.roles?.length
      ? capitalizeStringUpdated(
          user?.roles
            ?.map((role) => role?.roleName)
            .join(", ")
            .split("-")
            .join(" ")
        )
      : "-",
    location:
      user.addresses && user.addresses.length > 0
        ? user.addresses[0]?.city || user.addresses[0]?.state
          ? capitalizeStringUpdated(
              `${user.addresses[0]?.city ?? ""}${
                user.addresses[0]?.city && user.addresses[0]?.state ? ", " : ""
              }${user.addresses[0]?.state ?? ""}`
            )
          : "-"
        : "-",
    email: user.email,
    createdDate: extractDayMonthYearFromTimestamp(user.creationDt) || "-",
  }));
  return (
    <Box background={"white"} borderRadius={"12px"}>
      <Table
        overflow="auto"
        overflowY="auto"
        // tableContainerHeight={{ base: "441px", sm: "378px" }}
        headerList={isClientUser ? HeaderForClientUsers : HeaderForMyUsers}
        dataList={isClientUser ? tableListForClientUser : tableListForMyUsers}
        stickyColumn={["Actions"]}
      />
    </Box>
  );
};

export default UserTable;
