import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  imageBlob: null,
  loading: false,
  allUsersForCustomer: [],
  profileImgUrlsArr: [],
  allCustomerUserRoles: [],
  userData: null,
  avatarUrl: null,
  departmentDropDownForCustomer: null,
  designationDropDownForCustomer: null,
  roleDropDownForCustomer: null,
  stateDropDownForCustomer: null,
  cityDropDownForCustomer: null,
  allUsersForClient: [],
  clientsOfCustomer: [],
  clientUserRoles: [],
  clientUserData: null,
  profileImgUrlsArrOfClient: [],
  profileImgUrlsArrForClientUser: [],
  locations: {},
  adminRoleCount: null,
};

const userPage = createSlice({
  name: "userPage",
  initialState,
  reducers: {
    fetchAllUsersforCustomer: (state, action) => {
      state.loading = true;
    },
    fetchAllUsersforCustomerSuccess: (state, action) => {
      const users = action.payload.response;
      state.allUsersForCustomer = users;
      state.loading = false;
      if (
        state.departmentDropDownForCustomer === null &&
        state.designationDropDownForCustomer === null &&
        state.roleDropDownForCustomer === null &&
        state.stateDropDownForCustomer === null
      ) {
        const userCopy = JSON.parse(JSON.stringify(users)).filter(
          (obj) =>
            obj.department !== null &&
            obj.department !== undefined &&
            obj.designation !== null &&
            obj.designation !== undefined &&
            obj.roles !== null &&
            obj.roles !== undefined &&
            obj.addresses !== null &&
            obj.addresses !== undefined
        );
        const uniqueDepartment = userCopy.reduce((acc, obj) => {
          if (!acc.some((item) => item.category === obj.department)) {
            acc.push({ id: obj.department, category: obj.department });
          }
          return acc;
        }, []);
        const uniqueDesignation = userCopy.reduce((acc, obj) => {
          if (!acc.some((item) => item.category === obj.designation)) {
            acc.push({ id: obj.designation, category: obj.designation });
          }
          return acc;
        }, []);
        const uniqueRoles = userCopy.reduce((acc, obj) => {
          obj.roles.forEach((role) => {
            if (!acc.some((item) => item.category === role.roleName)) {
              acc.push({ id: role.roleName, category: role.roleName });
            }
          });
          return acc;
        }, []);

        const uniqueState = userCopy.reduce((acc, obj) => {
          obj.addresses.forEach((address) => {
            if (!acc.some((item) => item.state === address.state)) {
              acc.push({ state: address.state });
            }
          });
          return acc;
        }, []);

        const uniqueCity = userCopy.reduce((acc, obj) => {
          obj.addresses.forEach((address) => {
            if (!acc.some((item) => item.city === address.city)) {
              acc.push({ city: address.city });
            }
          });
          return acc;
        }, []);
        state.departmentDropDownForCustomer = uniqueDepartment;
        state.designationDropDownForCustomer = uniqueDesignation;
        state.roleDropDownForCustomer = uniqueRoles;
        state.stateDropDownForCustomer = uniqueState;
        state.cityDropDownForCustomer = uniqueCity;
        // console.log(
        //   "filters data",
        //   state.stateDropDownForCustomer,
        //   state.cityDropDownForCustomer
        // );
      }
    },
    fetchAllUsersforCustomerFailure: (state, action) => {
      state.loading = false;
    },
    fetchProfileImgURLArr: (state, action) => {
      state.loading = true;
    },
    fetchProfileImgURLArrSuccess: (state, action) => {
      state.loading = false;
      state.profileImgUrlsArr = action.payload;
    },
    fetchProfileImgURLArrFailure: (state, action) => {
      state.loading = false;
    },
    fetchAllCustomerUserRoles: (state, action) => {
      state.loading = true;
    },
    fetchAllCustomerUserRolesSuccess: (state, action) => {
      state.loading = false;
      state.allCustomerUserRoles = action.payload;
    },
    fetchAllCustomerUserRolesFailure: (state, action) => {
      state.loading = false;
    },
    fetchCreateUserRequest: (state, action) => {
      state.loading = true;
    },
    fetchCreateUserSuccess: (state, action) => {
      state.loading = false;
    },
    fetchCreateUserFailure: (state, action) => {
      state.loading = false;
    },
    fetchCreateAddressRequest: (state, action) => {
      state.loading = true;
    },
    fetchCreateAddressSuccess: (state, action) => {
      state.loading = false;
    },
    fetchCreateAddressFailure: (state, action) => {
      state.loading = false;
    },
    fetchUserDataRequest: (state, action) => {
      state.loading = true;
    },
    fetchUserDataSuccess: (state, action) => {
      state.loading = false;
      state.userData = action.payload;
    },
    fetchUserDataFailure: (state, action) => {
      state.loading = false;
    },
    fetchAvatarURL: (state, action) => {
      state.loading = true;
    },
    getAvatarURLSuccess: (state, action) => {
      state.loading = false;
      state.avatarUrl = action.payload;
    },
    getAvatarURLFailure: (state, action) => {
      state.loading = false;
    },
    fetchUpdateUserRequest: (state, action) => {
      state.loading = true;
    },
    fetchUpdateUserSuccess: (state, action) => {
      state.loading = false;
    },
    fetchUpdateUserFailure: (state, action) => {
      state.loading = false;
    },
    fetchUpdateAddressRequest: (state, action) => {
      state.loading = true;
    },
    fetchUpdateAddressSuccess: (state, action) => {
      state.loading = false;
    },
    fetchUpdateAddressFailure: (state, action) => {
      state.loading = false;
    },
    clearStates: (state, action) => {},
    clearStateSuccess: (state, action) => {
      state.avatarUrl = null;
      state.userData = null;
      state.clientUserData = null;
    },

    // for clientUser
    fetchAllUsersforClient: (state, action) => {
      state.loading = true;
    },
    fetchAllUsersforClientSuccess: (state, action) => {
      const users = action.payload.response;
      state.allUsersForClient = users;
      state.loading = false;
    },
    fetchAllUsersforClientFailure: (state, action) => {
      state.loading = false;
    },

    fetchProfileImgURLArrForClientUser: (state, action) => {
      state.loading = true;
    },
    fetchProfileImgURLArrForClientUserSuccess: (state, action) => {
      state.loading = false;
      state.profileImgUrlsArrForClientUser = action.payload;
    },
    fetchProfileImgURLArrForClientUserFailure: (state, action) => {
      state.loading = false;
    },

    fetchProfileImgURLArrOfClient: (state, action) => {
      state.loading = true;
    },
    fetchProfileImgURLArrOfClientSuccess: (state, action) => {
      state.loading = false;
      state.profileImgUrlsArrOfClient = action.payload;
    },
    fetchProfileImgURLArrOfClientFailure: (state, action) => {
      state.loading = false;
    },

    fetchClientsOfCustomer: (state, action) => {
      state.loading = true;
    },
    fetchClientsOfCustomerSuccess: (state, action) => {
      state.loading = false;
      state.clientsOfCustomer = action.payload;
    },
    fetchClientsOfCustomerFaliure: (state, action) => {
      state.loading = false;
    },

    fetchClientUserRoles: (state, action) => {
      state.loading = true;
    },
    fetchClientUserRolesSuccess: (state, action) => {
      state.loading = false;
      state.clientUserRoles = action.payload;
    },
    fetchClientUserRolesFailure: (state, action) => {
      state.loading = false;
    },
    createUserForClient: (state, action) => {
      state.loading = true;
    },
    createUserForClientSuccess: (state, action) => {
      state.loading = false;
    },
    createUserForClientFailure: (state, action) => {
      state.loading = false;
    },
    createAddressForClientUser: (state, action) => {
      state.loading = true;
    },
    createAddressForClientUserSuccess: (state, action) => {
      state.loading = false;
    },
    createAddressForClientUserFailure: (state, action) => {
      state.loading = false;
    },
    updateClientUserData: (state, action) => {
      state.loading = true;
    },
    updateClientUserDataSuccess: (state, action) => {
      state.loading = false;
    },
    updateClientUserDataFailure: (state, action) => {
      state.loading = false;
    },

    updateAddressForClientUser: (state, action) => {
      state.loading = true;
    },
    updateAddressForClientUserSuccess: (state, action) => {
      state.loading = false;
    },
    updateAddressForClientUserFailure: (state, action) => {
      state.loading = false;
    },
    fetchClientUserData: (state, action) => {
      state.loading = true;
    },
    fetchClientUserDataSuccess: (state, action) => {
      state.loading = false;
      state.clientUserData = action.payload;
    },
    fetchClientUserDataFailure: (state, action) => {
      state.loading = false;
    },
    //Location services
    fetchLocationData: (state, action) => {
      state.loading = true;
    },
    fetchLocationDataSuccess: (state, action) => {
      state.loading = false;
      const data = { ...state.locations };
      const { id, response } = action.payload;
      data[id] = response;
      state.locations = data;
      // console.log("clientLocationslice->", state.locations)
    },
    fetchLocationDataFailure: (state, action) => {
      state.loading = false;
    },

    ChangeUserStatus: (state, action) => {
      state.loading = true;
    },
    ChangeUserStatusSuccess: (state, action) => {
      state.loading = false;
    },
    ChangeUserStatusFailure: (state, action) => {
      state.loading = false;
    },
    deleteUser: (state, action) => {
      state.loading = true;
    },
    deleteUserSuccess: (state, action) => {
      state.loading = false;
    },
    deleteUserFailure: (state, action) => {
      state.loading = false;
    },

    deleteUserAddress: (state, action) => {
      state.loading = true;
    },
    deleteUserAddressSuccess: (state, action) => {
      state.loading = false;
      const copyUserData = _.cloneDeep(state.userData);
      if (copyUserData) copyUserData.addresses = null;

      const copyClientUserData = _.cloneDeep(state.clientUserData);
      if (copyClientUserData) copyClientUserData.addresses = null;

      state.userData = copyUserData;
      state.clientUserData = copyClientUserData;
    },
    deleteUserAddressFailure: (state, action) => {
      state.loading = false;
    },
    getUserByRole: (state, action) => {
      state.loading = true;
    },
    getUserByRoleSuccess: (state, action) => {
      state.loading = false;
      state.adminRoleCount = action.payload?.length;
    },
    getUserByRoleFailure: (state, action) => {
      state.loading = false;
    },
    clearAllStates: (state, action) => {},
    clearAllStateSuccess: (state, action) => {
      state.imageBlob = null;
      state.loading = false;
      state.allUsersForCustomer = [];
      state.profileImgUrlsArr = [];
      state.allCustomerUserRoles = [];
      state.userData = null;
      state.avatarUrl = null;
      state.departmentDropDownForCustomer = null;
      state.designationDropDownForCustomer = null;
      state.roleDropDownForCustomer = null;
      state.stateDropDownForCustomer = null;
      state.cityDropDownForCustomer = null;
      state.allUsersForClient = [];
      state.clientsOfCustomer = [];
      state.clientUserRoles = [];
      state.clientUserData = null;
      state.profileImgUrlsArrOfClient = [];
      state.profileImgUrlsArrForClientUser = [];
    },
  },
});

export const { actions, reducer, name: sliceKey } = userPage;
