import { createSlice } from "@reduxjs/toolkit";
export const initialState = {
  loading: false,
  clientDetails: null,
  customerAddresses: null,
  avatarUrl: null,
  clientAddress: null,
  clientLink: null,
  clientsOfCustomer: null,
  clientDetail: null,
  clientLinks: null,
  clientAddresses: null,
  profileImgUrlsArr: [],
  stateDropdown: null,
  cityDropdown: null,
  locations: {},
  // allUsersForClient: null,
  // allClientUserRoles: null,
  warehouseDetails: null,
};

const clientPageSlice = createSlice({
  name: "clientPage",
  initialState,
  reducers: {
    fetchClientsOfCustomer: (state, action) => {
      state.loading = true;
    },
    fetchClientsOfCustomerSuccess: (state, action) => {
      state.loading = false;
      state.clientsOfCustomer = action.payload;
      if (state.stateDropdown === null && state.cityDropdown === null) {
        const clientCopy = JSON.parse(
          JSON.stringify(
            action.payload.map((obj) => {
              if (obj.addresses?.length > 0) {
                return obj?.addresses[0];
              }
            })
          )
        ).filter(
          (obj) =>
            obj &&
            obj.city !== null &&
            obj.city !== undefined &&
            obj.state !== null &&
            obj.state !== undefined
        );
        const uniqueCity = clientCopy.reduce((acc, obj) => {
          // Check if city is already in the accumulator array
          if (!acc.some((item) => item.category === obj.city)) {
            acc.push({ id: obj.city, category: obj.city });
          }
          return acc;
        }, []);
        const uniqueState = clientCopy.reduce((acc, obj) => {
          // Check if city is already in the accumulator array
          if (!acc.some((item) => item.category === obj.state)) {
            acc.push({ id: obj.state, category: obj.state });
          }
          return acc;
        }, []);
        state.stateDropdown = uniqueState;
        state.cityDropdown = uniqueCity;
      }
    },
    fetchClientsOfCustomerFaliure: (state, action) => {
      state.loading = false;
    },
    postClientDataRequest: (state, action) => {
      state.loading = true;
    },
    postClientDataSuccess: (state, action) => {
      state.loading = false;
    },
    postClientDataFaliure: (state, action) => {
      state.loading = false;
    },
    fetchOneClientDetails: (state, action) => {
      state.loading = true;
    },
    fetchOneClientDetailsSuccess: (state, action) => {
      state.loading = false;
      state.clientDetail = action.payload;
    },
    fetchOneClientDetailsFailure: (state, action) => {
      state.loading = false;
    },
    fetchOneClientLinks: (state, action) => {
      state.loading = true;
    },
    fetchOneClientLinksSuccess: (state, action) => {
      state.loading = false;
      const data = { ...state.clientLink };
      const { clientId, response } = action.payload;
      data[clientId] = response;
      state.clientLink = data;
    },
    fetchOneClientLinksFailure: (state, action) => {
      state.loading = false;
    },
    fetchOneClientAddresses: (state, action) => {
      state.loading = true;
    },
    fetchOneClientAddressesSuccess: (state, action) => {
      state.loading = false;
      const data = { ...state.clientAddresses };
      const { clientId, response } = action.payload;
      data[clientId] = response;
      state.clientAddresses = data;
    },
    fetchOneClientAddressesFailure: (state, action) => {
      state.loading = false;
    },

    deleteClient: (state, action) => {
      state.loading = true;
    },
    deleteClientSuccess: (state, action) => {
      state.loading = false;
    },
    deleteClientFailure: (state, action) => {
      state.loading = false;
    },
    deleteClientAddress: (state, action) => {
      state.loading = true;
    },
    deleteClientAddressSuccess: (state, action) => {
      state.loading = false;
    },
    deleteClientAddressFailure: (state, action) => {
      state.loading = false;
    },
    fetchProfileImgURLArr: (state, action) => {
      state.loading = true;
    },
    fetchProfileImgURLArrSuccess: (state, action) => {
      state.loading = false;
      state.profileImgUrlsArr = action.payload;
    },
    fetchProfileImgURLArrFailure: (state, action) => {
      state.loading = false;
    },
    postClientAddressRequest: (state, action) => {
      state.loading = true;
    },
    postClientAddressSuccess: (state, action) => {
      state.loading = false;
    },
    postClientAddressFaliure: (state, action) => {
      state.loading = false;
    },
    fetchClientDataRequest: (state, action) => {
      state.loading = true;
    },
    fetchClientDataSuccess: (state, action) => {
      state.loading = false;
      state.clientDetails = action.payload;
    },
    fetchClientDataFaliure: (state, action) => {
      state.loading = false;
    },
    fetchClientAddressRequest: (state, action) => {
      state.loading = true;
    },
    fetchClientAddressSuccess: (state, action) => {
      state.loading = false;
      state.clientAddress = action.payload;
    },
    fetchClientaddressFaliure: (state, action) => {
      state.loading = false;
    },
    fetchClientLinksRequest: (state, action) => {
      state.loading = true;
    },
    fetchClientLinksSuccess: (state, action) => {
      state.loading = false;
      state.clientLinks = action.payload;
    },
    fetchClientLinksFaliure: (state, action) => {
      state.loading = false;
    },
    fetchAvatarURL: (state, action) => {
      state.loading = true;
    },
    getAvatarURLSuccess: (state, action) => {
      state.loading = false;
      state.avatarUrl = action.payload;
    },
    getAvatarURLFailure: (state, action) => {
      state.loading = false;
    },
    updateClientData: (state, action) => {
      state.loading = true;
    },
    updateClientDataSuccess: (state, action) => {
      state.loading = false;
    },
    updateClientDataFailure: (state, action) => {
      state.loading = false;
    },
    updateClientAddress: (state, action) => {
      state.loading = true;
    },
    updateClientAddressSuccess: (state, action) => {
      state.loading = false;
    },
    updateClientAddressFailure: (state, action) => {
      state.loading = false;
    },
    deleteClientAddress: (state, action) => {
      state.loading = true;
    },
    deleteClientAddressSuccess: (state, action) => {
      state.loading = false;
    },
    deleteClientAddressFailure: (state, action) => {
      state.loading = false;
    },
    fetchChangeClientStatus: (state, action) => {
      state.loading = true;
    },
    fetchChangeClientStatusSuccess: (state, action) => {
      state.loading = false;
    },
    fetchChangeClientStatusFailure: (state, action) => {
      state.loading = false;
    },
    clearClientAddress: (state, action) => {
      state.loading = true;
    },
    clearClientAddressSuccess: (state, action) => {
      state.loading = false;
      state.clientAddress = null;
    },
    clearClientAddressFailure: (state, action) => {
      state.loading = false;
    },
    fetchLocationData: (state, action) => {
      state.loading = true;
    },
    fetchLocationDataSuccess: (state, action) => {
      state.loading = false;
      const data = { ...state.locations };
      const { id, response } = action.payload;
      data[id] = response;
      state.locations = data;
      // console.log("clientLocationslice->", state.locations)
    },
    fetchLocationDataFailure: (state, action) => {
      state.loading = false;
    },

    clearStates: (state, action) => {},
    clearStateSuccess: (state, action) => {
      state.clientDetail = null;
      state.clientDetails = null;
      state.clientAddress = null;
      state.clientLinks = null;
      state.avatarUrl = null;
      state.warehouseDetails = null;
    },
    clearAllStates: (state, action) => {},
    clearAllStateSuccess: (state, action) => {
      state.clientDetail = null;
      state.clientDetails = null;
      state.clientAddress = null;
      state.clientLinks = null;
      state.avatarUrl = null;
      state.customerAddresses = null;
      state.avatarUrl = null;
      state.clientLink = null;
      state.clientsOfCustomer = null;
      state.clientAddresses = null;
      state.profileImgUrlsArr = [];
      state.stateDropdown = null;
      state.cityDropdown = null;
    },
    fetchPostMultipleClientLocation: (state, action) => {
      state.loading = true;
    },
    fetchPostMultipleClientLocationSuccess: (state, action) => {
      state.loading = false;
    },
    fetchPostMultipleCLientLocationFailure: (state, action) => {
      state.loading = false;
    },
    createClientLocation: (state, action) => {
      state.loading = true;
    },
    createClientLocationSuccess: (state, action) => {
      state.loading = false;
    },
    createClientLocationFailure: (state, action) => {
      state.loading = false;
    },
    fetchWarehouseDetailsRequest: (state, action) => {
      state.loading = true;
    },
    fetchWarehouseDetailsSuccess: (state, action) => {
      state.loading = false;
      state.warehouseDetails = action.payload;
    },
    fetchWarehouseDetailsFailure: (state, aciton) => {
      state.loading = false;
    },
    deleteLocation: (state, aciton) => {
      state.loading = true;
    },
    deleteLocationSuccess: (state, aciton) => {
      state.loading = false;
    },
    deleteLocationFailure: (state, aciton) => {
      state.loading = false;
    },
    updateClientLocation: (state, aciton) => {
      state.loading = true;
    },
    updateClientLocationSuccess: (state, aciton) => {
      state.loading = false;
    },
    upadateClientLocationFailure: (state, action) => {
      state.loading = false;
    },
    updateLocationStatus: (state, aciton) => {
      state.loading = true;
    },
    updateLocationStatusSuccess: (state, aciton) => {
      state.loading = false;
    },
    updateLocationStatusFailure: (state, action) => {
      state.loading = false;
    },
  },
});

export const { actions, reducer, name: sliceKey } = clientPageSlice;
