import React from "react";
import {
  Button,
  Flex,
  HStack,
  Tab,
  TabList,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

const UserTop = ({
  totalUsers,
  isClientUser,
  setIsClientUser,
  ownerEntityId,
  userRole,
}) => {
  return (
    <HStack justifyContent={"space-between"} alignItems={"flex-start"}>
      {/* <Text fontSize={"18px"} fontWeight={700}>
        Users
      </Text> */}
      <Text
        fontSize={"18px"}
        fontWeight={700}
        lineHeight={"25.2px"}
        color={"var(#2D3748)"}
      >
        {isClientUser
          ? totalUsers < 2
            ? totalUsers + " Client User"
            : totalUsers + " Client Users"
          : totalUsers < 2
          ? totalUsers + " My User"
          : totalUsers + " My Users"}
      </Text>

      <Flex gap={"24px"}>
        <Tabs colorScheme="teal">
          <TabList>
            <Tab onClick={() => setIsClientUser(false)}>My Users</Tab>
            <Tab onClick={() => setIsClientUser(true)}>Client Users</Tab>
          </TabList>
        </Tabs>
        {userRole !== "manager" ? (
          <Link to={`/admin/users/addUser?customerId=${ownerEntityId}`}>
            <Button
              colorScheme="login"
              bg={"#38B2AC"}
              size="md"
              borderRadius={"8px"}
            >
              + Add User
            </Button>
          </Link>
        ) : (
          <></>
        )}
      </Flex>
    </HStack>
  );
};

export default UserTop;
