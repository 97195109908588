import React from "react";
import { HStack, Input } from "@chakra-ui/react";
import { MdOutlineNumbers } from "react-icons/md";

export default function Number({ data, formData, handleFormDataChange }) {
  return (
    <HStack
      w="100%"
      h="48px"
      style={{ border: "1px solid #E2E8F0", borderRadius: "8px" }}
    >
      <HStack w="48px" h="48px" justify="center" align="center">
        <MdOutlineNumbers size={18} style={{ fill: "#718096" }} />
      </HStack>
      <Input
        variant="unstyled"
        type="text"
        inputMode="numeric"
        placeholder={data.element.placeholder}
        required={data.element.mandatory}
        color="#2D3748"
        _placeholder={{ color: "#A0AEC0" }}
        name={data.element.id}
        value={formData[data.element.id]?.value}
        onChange={handleFormDataChange}
        onInput={(e) => {
          const inputValue = e.target.value;
          const decimalCount = (inputValue.match(/\./g) || []).length;
          if (decimalCount <= 1) {
            e.target.value = inputValue.replace(/[^0-9.]/g, "");
          } 
          else {
            e.target.value = inputValue.replace(/[.](?=.*[.])/g, "");
          }
        }}
      />
    </HStack>
  );
}
