import React from "react";
import { HStack, VStack, Text, Box, useMediaQuery } from "@chakra-ui/react";
import * as selectors from "../selectors";
import { useSelector } from "react-redux";
import {
  ChevronDownIcon as CollapseIcon,
  ChevronRightIcon as ExpandIcon,
} from "@chakra-ui/icons";
import Element from "../element";

export default function Section({
  section,
  getElements,
  formData,
  handleFormDataChange,
  handleFilesChange,
}) {
  const sections = useSelector(selectors.selectSectionsForBlocks);
  const blocks = sections?.find((currSection) => currSection.id === section.id)
    ?.structure?.blocks;
  const [isMobileScreen] = useMediaQuery("(max-width: 872px)");
  const getReviewData = localStorage.getItem("reviewData");
  return (
    <VStack w="100%" aria-label="section" gap="24px">
      {blocks?.map((block) => {
        return (
          <VStack w="100%" aria-label="section" gap="24px" key={block.id}>
            <HStack
              style={{
                background: "#EDF2F7",
                borderRadius: "8px",
                padding: "8px 16px 8px 8px",
                width: "100%",
              }}
              justify="space-between"
              align="flex-start"
            >
              <HStack justify="flex-start" align="flex-start">
                <CollapseIcon w="26px" h="26px" color="#718096" />
                <VStack align="flex-start">
                  <Text
                    color="#4A5568"
                    fontSize={isMobileScreen ? "16px" : "22px"}
                    fontWeight="600"
                    lineHeight="1.4"
                  >
                    {block.title}
                  </Text>
                  <Text
                    color="#718096"
                    fontSize={isMobileScreen ? "10px" : "12px"}
                    fontWeight="400"
                    lineHeight="1.4"
                  >
                    {block.description}
                  </Text>
                </VStack>
              </HStack>
              {/* <Text color="#4A5568" fontSize="16px" fontWeight="400" lineHeight="1.4">
          0 / 1 (0%)
        </Text> */}
            </HStack>
            {getElements(block.id)?.map((el) => {
              return (
                <Box
                  aria-label="component"
                  key={el.element.id}
                  style={{
                    padding: "20px",
                    width: "100%",
                    border: "1.5px solid #CBD5E0",
                    borderRadius: "15px",
                    background: "#FFF",
                    boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
                  }}
                >
                  <Text
                    aria-label="element label"
                    color="#2D3748"
                    fontSize="16px"
                    fontWeight="500"
                    lineHeight="1.4"
                  >
                    {el.element.label}
                    {el.element.mandatory ? (
                      <span
                        style={{
                          color: "#D93025",
                          paddingLeft: "6px",
                          fontSize: "20px",
                        }}
                      >
                        *
                      </span>
                    ) : (
                      <></>
                    )}
                  </Text>
                  <Element
                    elementType={el.element.htmlType}
                    formData={formData}
                    handleFormDataChange={handleFormDataChange}
                    data={el}
                    handleFilesChange={handleFilesChange}
                    // setPage={setPage}
                    // displayData={displayData}
                    // navIndex={navIndex}
                    // setNavIndex={setNavIndex}
                    // formErrorData={formErrorData}
                  />
                  {/* {formErrorData && formErrorData[el.element.id] ? (
              <Text
                aria-label="element label"
                color="#D93025"
                fontSize="12px"
                fontWeight="400"
                lineHeight="1.4"
              >
                {formErrorData[el.element.id]}
              </Text>
            ) : (
              <></>
            )} */}
                  <Text
                    aria-label="element label"
                    color="#718096"
                    fontSize="12px"
                    fontWeight="400"
                    lineHeight="1.4"
                  >
                    {el.element.supportingText}
                  </Text>
                </Box>
              );
            })}
          </VStack>
        );
      })}
    </VStack>
  );
}
